/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import "../scss/main.scss"

/**
 * Main React application entry-point for both the server and client.
 */
class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobileSafari: "",
      isTabletSafari: "",
      showBanner: true,
      showOpen: true,
    }
  }

  /**
   * componentWillMount() runs on server and client.
   */
  componentWillMount() {
    // if (__SERVER__) {
    // 	console.log("Hello server main");
    // }
    //
    // if (__CLIENT__) {
    // 	console.log("Hello client main");
    // }
  }

  /**
   * componentDidUpdate() only runs on the client.
   */
  componentDidUpdate(prevProps, prevState) {
    // if (!this.props.additionalStargazers) {
    // 	return;
    // }
    //
    // this.loadMoreStargazersOnClient();
  }

  componentDidMount() {
    let UA = navigator.userAgent,
      isIos = /ip(hone|od|ad)/i.test(UA),
      isSafari = /(mac os x).*version\/\d(.\d)+ (mobile\/\w{5,} )?safari/i.test(
        UA
      ),
      isKindleFire = /KF[A-Z]{2,3}/.test(UA),
      isPhone =
        ((/mobile/i.test(UA) && !/ipad|tablet/i.test(UA)) ||
          matchMedia("only screen and (max-device-width : 767px)").matches) &&
        !isKindleFire,
      isAndroid = /android/i.test(UA),
      isTablet = (isAndroid && !isPhone) || (isIos && !isPhone) || isKindleFire

    if (isIos && isSafari && isPhone)
      this.setState({ isMobileSafari: "iosMobileSafari" })
    if (isIos && isSafari && isTablet)
      this.setState({ isTabletSafari: "iosTabletSafari" })

    this.handleLocalStorage()
  }

  handleLocalStorage() {
    localStorage.removeItem("showOverlay")
    localStorage.removeItem("showIcon")

    document.cookie = "noLibsSeen=;expires=Thu, 01 Jan 1970 00:00:01 GMT;"
    document.cookie = "soStCloseSeen=;expires=Thu, 01 Jan 1970 00:00:01 GMT;"

    let twenStShow = document.cookie.replace(
      /(?:(?:^|.*;\s*)twenStShow\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    )
    let openShow = document.cookie.replace(
      /(?:(?:^|.*;\s*)openShow\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    )

    if (twenStShow === "false") {
      this.setState({
        showBanner: false,
      })
    }
    if (openShow === "false") {
      this.setState({
        showOpen: false,
      })
    }
  }

  onClose = () => {
    this.setState({
      showBanner: false,
    })

    document.cookie = "twenStShow=false"
  }

  onOpenClose = () => {
    this.setState({
      showOpen: false,
    })

    document.cookie = "openShow=false"
  }

  /**
   * Runs on server and client.
   */
  render() {
    const { showBanner, showOpen } = this.state
    return (
      <div
        className={`${this.state.isMobileSafari} ${this.state.isTabletSafari} ${
          showBanner ? "banner-visible" : ""
        }`}
        >
        {this.props.children}
      </div>
    )
  }
}
export default Layout

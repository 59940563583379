import React from "react"

import Layout from "../components/layout"
class Home extends React.Component {
  render() {
    return (
      <Layout>
        <div className="index-container">
          <div className="index-container-content">
            <div className="index-container-image">
              <img src="/images/logo_bb.svg" alt="blackbird logo" />
            </div>
            <div className="index-container-message">
              <p>After nearly 12 years of business, we have decided to close down the restaurant. Thank you to everyone that has supported us over the years.  </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Home
